import { createApp } from 'vue'
import App from './app/App.vue'
import router from './app/router'
import './assets/tailwind.css'
import './assets/base.scss'
import './assets/vueprime-theme/theme.css'

// icons
import 'primeicons/primeicons.css'
import PrimeVue from 'primevue/config'
import { createPinia } from 'pinia'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'
import DialogService from 'primevue/dialogservice'
import Ripple from 'primevue/ripple'
import ConfirmationService from 'primevue/confirmationservice'
import { localeRu } from './app/locale'

createApp(App)
  .use(PrimeVue, {
    locale: localeRu,
    ripple: true,
  })
  .use(ToastService)
  .use(DialogService)
  .use(ConfirmationService)
  .directive('ripple', Ripple)
  .directive('tooltip', Tooltip)
  .use(createPinia())
  .use(router)
  .mount('#app')
