<template>
  <form @submit.prevent="onSubmit">
    <div class="flex flex-col">
      <BaseInputForm
        id="email"
        class="w-full"
        label="Email"
        :error="email.meta.error"
        :required="true"
      >
        <InputText
          id="email"
          v-model="email.model"
          class="w-full"
          :invalid="email.meta.invalid"
          :disabled="pending"
          @blur="email.onBlur"
        />
      </BaseInputForm>

      <BaseInputForm
        id="password"
        class="w-full"
        label="Пароль"
        :error="password.meta.error"
        :required="true"
      >
        <Password
          id="password"
          v-model="password.model"
          toggleMask
          input-class="w-full"
          :invalid="password.meta.invalid"
          :feedback="false"
          :disabled="pending"
          @blur="password.onBlur"
        />
      </BaseInputForm>

      <div v-tooltip="disabledReason">
        <Button
          type="submit"
          label="Войти"
          class="mt-4 w-full"
          :loading="pending"
          :disabled="disabled"
        ></Button>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { useToast } from 'primevue/usetoast'
import { computed, toRefs } from 'vue'
import { useAuthModel } from '../../model'
import { BaseInputForm, emailValidator, requiredValidator, useForm } from '@/shared'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'

type LoginForm = {
  email: string
  password: string
}

const { add: addToast } = useToast()
const accountLoginModel = useAuthModel()
const { login } = toRefs(accountLoginModel)
const { post } = login.value
const { pending } = toRefs(login.value)

const form = useForm<LoginForm>({
  email: {
    validators: [requiredValidator, emailValidator]
  },
  password: {
    validators: [requiredValidator]
  }
})

const { email, password } = form.controls

const disabledReason = computed(() => {
  if (form.invalid.value) {
    return 'Форма не валидна'
  }

  if (pending.value) {
    return 'Происходит авторизация...'
  }

  return null
})

const disabled = computed(() => Boolean(disabledReason.value))

function onSubmit() {
  if (!disabled.value && email.model && password.model) {
    post(email.model, password.model)
  } else {
    addToast({ severity: 'warn', summary: 'Авторизация', detail: disabledReason.value })
  }
}
</script>
