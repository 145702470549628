import { User, useApiFetch } from '@/shared'

export type Login = {
  email: string
  password: string
  code?: string
}

export type LoginResponse = {
  user: User
  token: string
  token_expires: string
}

const baseAccountUlr = 'account/'

/** Авторизация */
export const useAccountLogin = () => useApiFetch<{ _data: Login }, LoginResponse>(baseAccountUlr + 'login', {}, 'json')
/** Выход */
export const useAccountLogout = () => useApiFetch<unknown>(baseAccountUlr + 'logout', {}, 'json')
/** Проверка авторизации */
export const useAccountCheckLogin = () => useApiFetch<LoginResponse>(baseAccountUlr + 'check_login', {}, 'json')
