import { customerApi } from '@/shared/api/api-customer'
import { useToast } from 'primevue/usetoast'
import { Ref, ComputedRef, ComponentPublicInstance, computed, toRaw, nextTick, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { Form, Nullable } from '@/shared'
import { useMortgageModel } from '../mortgage-model'
import { Customer, CustomerFields } from '@/entities/customer'


/**
 ** Фабричная функция, которая создает модель для работы с обновлением кастомера
 */
const useMortgageCustomerUpdateModel = (
  customerId: Ref<string | null>,
  customer: ComputedRef<Customer | null>,
  customerType: ComputedRef<'main' | 'customer'>,
  form: Form<Partial<Nullable<CustomerFields>>>,
  container: Ref<ComponentPublicInstance | undefined>,
  getCustomerFio: () => string,
  scrollToFirstError: () => void
) => {
  const router = useRouter()
  const { add: addToast } = useToast()
  const mortgageModel = useMortgageModel()
  const { mortgage, updatePending, updateCustomerPending: updateAppCustomerPending } = toRefs(mortgageModel)
  const { post: customerUpdatePost, pending: customerUpdatePending } = customerApi.update()

  const pending = computed(() => {
    if (updatePending.value || customerUpdatePending.value || updateAppCustomerPending.value) {
      return true
    }

    return false
  })

  const disabled = computed(() => {
    if (mortgage.value?.stages.current === 'app_issued' || pending.value) {
      return true
    }

    return false
  })

  const disabledReason = computed(() => {
    if (mortgage.value?.stages.current === 'app_issued') {
      return 'В завершенных заявках нельзя обновлять анкету'
    }

    if (pending.value) {
      return 'Происходит обновление анкеты'
    }

    return null
  })

  async function update() {
    const updateCustomerRes = await updateCustomer()
    if (updateCustomerRes?._code === 0 && updateCustomerRes._data) {
      if (customerType.value === 'main') {
        return updateMainCustomer()
      } else {
        return updateAppCustomer()
      }
    }
  }

  async function updateAppCustomer() {
    if (customerId.value) {
      const res = await mortgageModel.updateCustomer(customerId.value)
      if (res?._code === 0 && res._data) {
        addToast({ severity: 'success', summary: `Анкета обновлена`, detail: getCustomerFio() })
      } else {
        addToast({
          severity: 'error',
          summary: `Обновление анкеты`,
          detail: `${getCustomerFio()}. ${res?._message || ''}`.trim()
        })
      }
      return res
    }
  }

  function updateMainCustomer() {
    if (customer.value) {
      return updateAppCustomer()
    } else {
      return create()
    }
  }

  async function create() {
    if (customerId.value) {
      const res = await mortgageModel.create(customerId.value)
      if (res?._code === 0 && res._data) {
        router.push({ params: { id: res._data.id } })
        addToast({
          severity: 'success',
          summary: 'Первоначальное обновление анкеты',
          detail: 'Появилась возможность добавлять сканы и созаёмщиков!'
        })
      } else {
        addToast({ severity: 'error', summary: 'Первоначальное обновление анкеты', detail: res?._message })
      }
      return res
    }
  }

  async function updateCustomer() {
    const validationRes = customerValidation()
    if (validationRes) {
      const _data = toRaw(form.values.value) as any
      _data.citizenship = 'RU'
      _data.id = customerId.value

      // //!
      // _data.dependents_not_children = 0         
      // _data.dependents_not_children_expenses = 0         
      // _data.other_expenses = 0       
      // //!  

      const res = await customerUpdatePost({ _data })

      if (res?._code === 0 && res._data) {
        customerId.value = res._data.id
        await nextTick()
      } else if (res?._code !== 0) {
        addToast({
          severity: 'error',
          summary: `Обновление анкеты (${getCustomerFio()})`,
          detail: `${getCustomerFio()}. ${res?._message || ''}`.trim()
        })
        if (res?._code === 101 && res._detail) {
          form.setErrors(res._detail)
          scrollToFirstError()
        }
      }
      return res
    } else {
      return null
    }
  }

  function customerValidation() {
    const lastNameValidationResult = form.controls.last_name?.validation({ setTouched: true })
    const fistNameValidationResult = form.controls.first_name?.validation({ setTouched: true })
    const emailValidationResult = form.controls.email?.validation({ setTouched: true })
    const serialValidationResult = form.controls.passport_serial?.validation({ setTouched: true })
    const numberValidationResult = form.controls.passport_number?.validation({ setTouched: true })

    if (
      !lastNameValidationResult ||
      !fistNameValidationResult ||
      !emailValidationResult ||
      !serialValidationResult ||
      !numberValidationResult
    ) {
      addToast({
        severity: 'error',
        summary: 'Обновление анкеты',
        detail: 'Обязательные поля для сохранения анкеты: ФИО, email, серия и номер паспорта'
      })
      scrollToFirstError()
      return false
    }

    return true
  }

  return { update, pending, disabled, disabledReason, container }
}

export default useMortgageCustomerUpdateModel
