<template>
  <AutoComplete
    v-model="localModel"
    v-bind="{
      disabled,
      placeholder,
      invalid
    }"
    completeOnFocus
    autoOptionFocus
    input-class="w-full"
    option-label="title"
    :class="{ 'pointer-events-none': readonly }"
    :input-id="id"
    :suggestions="options"
    @blur="emits('blur', $event)"
    @focus="emits('focus', $event)"
    @complete="search"
  />
</template>

<script setup lang="ts">
import { defineModel, ref, watch } from 'vue'
import AutoComplete, { AutoCompleteCompleteEvent } from 'primevue/autocomplete'
import { storeToRefs } from 'pinia'
import { User, useApiFetch } from '@/shared'
import { userIsAdmin } from '@/shared'
import { useAuthModel } from '@/features/auth'

type SalespointGroup = {
  id: number
  title: string
  deleted_at: string | null
  _salespoints: { id: number; title: string }[]
}

const props = defineProps<{
  id?: string
  disabled?: boolean
  placeholder?: string
  invalid?: boolean
  readonly?: boolean
}>()

const emits = defineEmits<{
  blur: [Event]
  focus: [Event]
}>()

const { user } = storeToRefs(useAuthModel())

const { post } = useApiFetch<
  {
    _page_size?: number
    _filter: {
      title: string
      id?: number | number[]
    }
  },
  SalespointGroup[]
>('salespointgroup/get_list', {}, 'json')

const model = defineModel<number | null>()
const localModel = ref<SalespointGroup>()
const options = ref<SalespointGroup[]>([])

watch(localModel, localModel => {
  model.value = localModel?.id || null
})

async function getList(query: string) {
  const res = await post({
    _page_size: 5,
    _filter: {
      title: `%${query?.trim()}%`,
      id: userIsAdmin(user.value as User) ? undefined : user.value?.salespoint_group_staff
    }
  })

  return res?._data || []
}

async function search(event: AutoCompleteCompleteEvent) {
  options.value = await getList(event.query)
}
</script>
