<template>
  <div class="core-layout-header w-full bg-white shadow-lg px-8 fixed z-10">
    <div class="container mx-auto">
      <Menubar
        class="bg-transparent border-none p-0 py-3"
        breakpoint="1023px"
        :pt="{
          menu: 'lg:flex lg:w-full',
          start: 'w-full h-[35px] lg:w-fit',
          submenu: 'w-full'
        }"
        :model="items"
      >
        <template #start>
          <router-link :to="{ name: mortgageRouteNames.list }">
            <img
              src="@/assets/inpocket-full-logo.svg"
              class="mr-6"
              width="150"
              height="35"
            />
          </router-link>
        </template>

        <template #item="{ item, props, hasSubmenu, root }">
          <TheHeaderItem
            :root="root"
            :item="item"
            :props="props"
            :has-submenu="hasSubmenu"
          />
        </template>
      </Menubar>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSalespointStore } from '@/store'
import { computed, reactive, watch } from 'vue'
import { MenuItem } from 'primevue/menuitem'
import { useRouter } from 'vue-router'
import Menubar from 'primevue/menubar'
import { mortgageRouteNames } from '@/pages/mortgage'
import { useFeatureSelectSalespointModel } from '@/features/salespoint'
import TheHeaderItem from './TheHeaderItem.vue'
import { useAuthModel } from '@/features/auth'
import { accountRouteNames, ReportsRouteNames, UserRouteNames } from '@/app/router/routes'

type CoreLayoutMenuItem = MenuItem & {
  labelClass?: string
  tooltip?: string
}

const router = useRouter()

const authModel = useAuthModel()

const salespointStore = useSalespointStore()

const { openModal: openSelectSalespointModal } = useFeatureSelectSalespointModel()

const initials = computed(() => {
  if (authModel.user?.last_name && authModel.user?.first_name) {
    return `${authModel.user?.last_name} ${authModel.user?.first_name[0]?.toLocaleUpperCase()}.`
  }

  return ''
})

const userMenuItem = reactive<CoreLayoutMenuItem>({
  label: initials.value,
  icon: 'pi pi-user',
  route: UserRouteNames.base,
  params: {
    id: authModel.user?.id
  }
  // command: () => userProfileModal.open(authModel.user as User, { modal: false })
})

const salespointMenuItem = reactive<CoreLayoutMenuItem>({
  label: salespointStore.salespoint?.title || '',
  icon: 'pi pi-shopping-bag',
  class: 'lg:max-w-[200px]',
  command: () => openSelectSalespointModal()
})

const logoutMenuItem = reactive<CoreLayoutMenuItem>({
  label: 'Выйти',
  icon: 'pi pi-sign-out',
  tooltip: 'Выйти',
  labelClass: 'lg:hidden',
  command: () => {
    authModel.logout.post().then(() => {
      router.push({ name: accountRouteNames.login })
    })
  }
})

const reportsMenuItem = reactive({
  label: 'Отчеты',
  icon: 'pi pi-table',
  // route: ReportsRouteNames.base,
  visible: computed(
    () =>
      authModel.user?.raw_permissions?.includes('reports_get_own') ||
      authModel.user?.raw_permissions?.includes('reports_get_all')
  ),
  items: [
    {
      label: 'Статистика по заявкам',
      route: ReportsRouteNames.loan
    },
    {
      label: 'Статистика по точкам',
      route: ReportsRouteNames.stat,
      visible: computed(() => authModel.user?.raw_permissions?.includes('mortgage_get_bc'))
    }
  ]
})

const items = reactive<CoreLayoutMenuItem[]>([
  {
    label: 'Создать заявку',
    icon: 'pi pi-plus',
    route: mortgageRouteNames.finder
  },
  {
    label: 'Список заявок',
    icon: 'pi pi-list',
    route: mortgageRouteNames.list
  },
  reportsMenuItem,
  {
    separator: true,
    class: 'w-full m-0 border-gray-300 lg:flex-grow lg:w-fit'
  },
  userMenuItem,
  salespointMenuItem,
  {
    separator: true,
    class: 'w-full m-0 border-gray-300 lg:w-fit'
  },

  logoutMenuItem
])

// const secondItems = reactive<CoreLayoutMenuItem[]>([userMenuItem, salespointMenuItem, logoutMenuItem])

watch(
  () => salespointStore.salespoint,
  () => {
    salespointMenuItem.label = salespointStore.salespoint?.title || ''
  }
)
</script>
