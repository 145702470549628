<template>
  <AutoComplete
    v-model="localModel"
    v-bind="{
      disabled,
      placeholder,
      invalid
    }"
    completeOnFocus
    autoOptionFocus
    input-class="w-full"
    option-label="title"
    :multiple="multiple"
    :class="{ 'pointer-events-none': readonly }"
    :input-id="id"
    :suggestions="options"
    @blur="emits('blur', $event)"
    @focus="emits('focus', $event)"
    @complete="search"
  />
</template>

<script setup lang="ts">
import { defineModel, ref, watch } from 'vue'
import AutoComplete, { AutoCompleteCompleteEvent } from 'primevue/autocomplete'
import { Salespoint, useGetSalespointList, userIsAdmin } from '@/shared'
import { storeToRefs } from 'pinia'
import { User } from '@/entities/user'
import { useAuthModel } from '@/features/auth'

const props = defineProps<{
  id?: string
  disabled?: boolean
  placeholder?: string
  invalid?: boolean
  readonly?: boolean
  multiple?: boolean
}>()

const emits = defineEmits<{
  blur: [Event]
  focus: [Event]
}>()

const model = defineModel<number | number[] | null>()
const localModel = ref<Salespoint | Salespoint[] | string>()
const options = ref<Salespoint[]>([])
const { post } = useGetSalespointList()
const { user } = storeToRefs(useAuthModel())



watch(localModel, localModelValue => {
  if (localModelValue) {
    if (Array.isArray(localModelValue)) {
      if (localModelValue.length) {
        model.value = localModelValue.map(salespoint => salespoint.id)
      } else {
        model.value = null
      }
    } else if (typeof localModelValue === 'string') {
      model.value = null
    } else {
      model.value = localModelValue.id
    }
  } else {
    model.value = null
  }
})

async function getList(query: string) {
  const res = await post({
    _page_size: 5,
    _filter: {
      title: `%${query?.trim()}%`,
      id: userIsAdmin(user.value as User) ? undefined : user.value?.salespoint_staff
    }
  })

  // Список ТТ
  const list = (res?._data || []).filter(salespoint => {
    // При множественном выборе нужно отсекать повторяющиеся ТТ
    if (
      props.multiple &&
      Array.isArray(localModel.value) &&
      localModel.value.find(selectedSalespoint => salespoint.id === selectedSalespoint.id)
    ) {
      return false
    }

    return true
  })

  return list
}

async function search(event: AutoCompleteCompleteEvent) {
  options.value = await getList(event.query)
}
</script>
