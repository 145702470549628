import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { RoutesNames } from './router-names'
import mortgageRoutes, { mortgageRouteNames } from '@/pages/mortgage'
import { authRoutes, chatmessageRoutes, reportsRoutes, userRoutes } from './routes'

const notFoundRoute: RouteRecordRaw = {
  path: '/:pathMatch(.*)',
  redirect: { name: mortgageRouteNames.list }
}

/** Корневой маршрут */
const root: RouteRecordRaw = {
  path: '',
  name: RoutesNames.root,
  component: () => import('@/shared/components/TheLayout.vue'),
  children: [
    notFoundRoute,
    mortgageRoutes,
    reportsRoutes,
    userRoutes,
    chatmessageRoutes,
    { path: 'test', component: () => import('@/pages/MortgageTestPage') }
  ],
  redirect: { name: mortgageRouteNames.list }
}

/** Маршруты приложения */
const routes: Array<RouteRecordRaw> = [root, authRoutes]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeResolve((to, from, next) => {
  // Находим название страницы, если он прописан в мета данных
  const title = to.matched.reduce((t, r) => (t = (r?.meta.title as string) || t), 'Simpleloan Ипотека')
  window.document.title = title

  next()
})

export default router
