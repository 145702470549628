import { RouteRecordRaw } from 'vue-router'

export const mortgageRouteNames = {
  base: 'Mortgage',
  list: 'Mortgage List',
  calculate: 'Mortgage Calculate',
  app: 'Mortgage App1',
  finder: 'Mortgage Finder'
}

const mortgageRoutes: RouteRecordRaw = {
  path: 'mortgage',
  component: () => import('./MortgagePage'),
  name: mortgageRouteNames.base,
  redirect: { name: mortgageRouteNames.list },
  children: [
    {
      path: '',
      component: () => import('./MortgageTablePage'),
      name: mortgageRouteNames.list
    },
    {
      path: 'finder',
      component: () => import('./MortgageFinderPage'),
      name: mortgageRouteNames.finder
    },
    {
      path: 'app/:id?',
      component: () => import('./components/MortgageAppPage'),
      name: mortgageRouteNames.app
    },

    //!
    {
      path: 'finder2',
      component: () => import('./components/MortgageFinderPage')
    },
    {
      path: 'scans2',
      component: () => import('./components/MortgageScansPage')
    },
    {
      path: 'form2',
      component: () => import('./components/MortgageFormPage')
    }
  ]
}

export default mortgageRoutes
