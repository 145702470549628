import { useSalespointStore } from '@/store'
import { useDialog } from 'primevue/usedialog'
import { Salespoint } from 'simpleloan_api'
import { SelectSalespointModal } from '../components'
import { useSalespointModel } from '@/entities/salespoint'

export const useFeatureSelectSalespointModel = () => {
  const salespointModel = useSalespointModel()
  const salespointStore = useSalespointStore()
  const dialog = useDialog()

  function openModal() {
    return dialog.open(SelectSalespointModal, {
      props: {
        contentClass: 'p-0 bg-transparent',
        showHeader: false,
        style: 'max-width: 90vw;',
        closeOnEscape: Boolean(salespointStore.salespoint),
        modal: true,
        pt: {
          root: 'border-0 sm:w-[500px]'
        }
      }
    })
  }

  function select(salespoint: Salespoint) {
    salespointStore.set(salespoint)
    salespointModel.salespoint = salespoint
  }

  return { openModal, select }
}
