import { defineStore } from 'pinia'
import { LoginResponse, useAccountCheckLogin, useAccountLogin, useAccountLogout } from '../api'
import { useToast } from 'primevue/usetoast'
import { computed, ref } from 'vue'
import { loginResponseAdapter } from '../lib'
import { useSalespointStore } from '@/store'
import { User } from '@/shared'
import { useUserModel } from '@/entities/user'

export const useAuthModel = defineStore('auth-model', () => {
  const userModel = useUserModel()
  const user = ref<User | null>(null)

  const salespointStore = useSalespointStore()
  const { add: addToast } = useToast()
  const { post: loginPost, response: loginResponse, pending: loginPending, error: loginError } = useAccountLogin()
  const { post: logoutPost, response: logoutResponse, pending: logoutPending, error: logoutError } = useAccountLogout()
  const {
    post: checkLoginPost,
    response: checkLoginResponse,
    pending: checkLoginPending,
    error: checkLoginError
  } = useAccountCheckLogin()

  const isAuthorized = computed(() => Boolean(user.value))

  async function login(email: string, password: string) {
    const res = await loginPost({
      _data: {
        email,
        password
      }
    })

    if (res?._code === 0 && res._data) {
      await onLogin(res._data)
    } else {
      addToast({
        severity: 'error',
        summary: 'Авторизация',
        detail: loginResponseAdapter(res?._code, loginError.value)
      })
    }

    return res
  }

  async function logout() {
    const res = await logoutPost()

    if (res?._code === 0) {
      user.value = null
      localStorage.removeItem('token')
      salespointStore.drop()
    } else {
      addToast({ severity: 'error', summary: 'Выход', detail: loginError.value })
    }

    return res
  }

  async function checkLogin() {
    const res = await checkLoginPost()
    if (res?._code === 0 && res._data) {
      await onLogin(res._data)
    }

    return res
  }

  async function onLogin(res: LoginResponse) {
    user.value = res.user
    userModel.user = res.user
    localStorage.setItem('token', res.token)
    await salespointStore.setLocalStorageSalespoint()
  }

  return {
    user,
    isAuthorized,

    login: {
      post: login,
      response: loginResponse,
      pending: loginPending,
      error: loginError
    },

    logout: {
      post: logout,
      response: logoutResponse,
      pending: logoutPending,
      error: logoutError
    },

    checkLogin: {
      post: checkLogin,
      response: checkLoginResponse,
      pending: checkLoginPending,
      error: checkLoginError
    }
  }
})
