<template>
  <div>
    <!-- <div class="text-gray-600 text font-medium">Паспорт все страницы</div> -->
    <button
      ref="dropZoneRef"
      class="group relative w-full p-2 border border-dashed border-gray-400 rounded-md bg-white cursor-pointer h-[250px]"
      :class="{
        'hover:bg-gray-100': !model && !isHovered,
        'border-primary !bg-gray-100': isOverDropZone
      }"
      @click="
        () => {
          if (!model && !isHovered) {
            input?.click()
          }
        }
      "
    >
      <div
        v-if="loading"
        class="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-200 bg-opacity-80 rounded-md z-20"
      >
        <ProgressSpinner
          class="w-[50px] h-[50px]"
          strokeWidth="3"
        />
      </div>

      <div
        v-if="!loading"
        class="transition hidden p-2 absolute top-0 left-0 w-full h-full bg-gray-600 bg-opacity-80 rounded-md z-10 cursor-default"
        :class="{
          'group-hover:block': model
        }"
      >
        <div class="h-full flex flex-col justify-between">
          <div class="text-start text-base text-white">
            <div class="font-medium">{{ label }}</div>
            <div>{{ fileSize }}</div>
          </div>

          <div class="flex gap-2">
            <Button
              outlined
              class="text-sm text-white"
              icon="pi pi-image"
              label="Открыть"
              @click.stop="open"
            ></Button>

            <Button
              outlined
              class="text-sm text-white"
              icon="pi pi-upload"
              label="Скачать"
              @click.stop="download"
            ></Button>

            <Button
              outlined
              class="text-sm text-white"
              icon="pi pi-trash"
              label="Удалить"
              @click.stop="model = null"
            ></Button>
          </div>
        </div>
      </div>

      <div class="relative flex justify-center items-center">
        <Image
          v-if="img"
          ref="image"
          :src="img"
          :pt="{
            button: 'w-full'
          }"
          :preview="preview"
          imageClass="w-full max-h-[230px]"
        />

        <i
          v-if="model?.type.includes('pdf')"
          class="pi pi-file-pdf text-gray-400"
          style="font-size: 100px"
        ></i>

        <div
          v-if="!model"
          class="text-gray-400"
        >
          Перетащите файл или нажмите для выбора
        </div>

        <div
          v-show="!model"
          class="absolute top-[-100px] right-0"
          @mouseenter="isHovered = true"
          @mouseleave="isHovered = false"
        >
          <UtilsPdfMerge
            icon-class="text-2xl"
            @merge="onMerge"
          />
        </div>
      </div>
    </button>

    <input
      ref="input"
      hidden
      type="file"
      accept="image/png, image/jpeg, application/pdf"
      @input="onInput"
    />

    <Menu
      id="overlay_menu"
      ref="menu"
      :model="items"
      :pt="{
        root: 'text-base'
      }"
      :popup="true"
    />
  </div>
</template>

<script setup lang="ts">
import { UtilsPdfMerge, downloadFileLink } from '@/shared'
import Button from 'primevue/button'
import Image from 'primevue/image'
import Menu from 'primevue/menu'
import { MenuItem } from 'primevue/menuitem'
import ProgressSpinner from 'primevue/progressspinner'
import { reactive, ref, defineModel, computed, nextTick } from 'vue'
import { useDropZone } from '@vueuse/core'

const props = defineProps<{
  disabled?: boolean
  loading?: boolean
}>()

const model = defineModel<File | null>()
const menu = ref()
const input = ref<HTMLInputElement>()
const image = ref<InstanceType<typeof Image>>()
const preview = ref<boolean>(false)
const isHovered = ref<boolean>(false)

const dropZoneRef = ref<HTMLDivElement>()

const { isOverDropZone } = useDropZone(dropZoneRef, {
  onDrop
  // dataTypes: ['image/jpeg', 'image/jpeg', 'application/pdf']
})

const menuItemFileName = reactive({
  label: computed(() => model.value?.name)
})

const menuItemOpen = reactive({
  label: 'Открыть',
  icon: 'pi pi-image',
  disabled: computed(() => !model.value),
  command: () => {
    if (model.value) {
      if (img.value) {
        preview.value = true
        nextTick(() => {
          image.value?.onImageClick()
          preview.value = false
        })
      } else {
        console.log('this is PDF', model.value)

        window.open(URL.createObjectURL(model.value), '_blank')
      }
    }
  }
})
const menuItemUpload = reactive({
  label: 'Скачать',
  icon: 'pi pi-upload',
  disabled: computed(() => !model.value),
  command: () => {
    if (model.value) {
      downloadFileLink(model.value, model.value?.name)
    }
  }
})
const menuItemDelete = reactive({
  label: 'Удалить',
  icon: 'pi pi-trash',
  disabled: computed(() => !model.value),
  command: () => {
    model.value = null
  }
})

const items = reactive<MenuItem[]>([menuItemFileName, menuItemOpen, menuItemUpload, menuItemDelete])
const label = computed(() => (model.value ? model.value.name : 'Загрузить файл'))

const fileSize = computed(() => {
  if (model.value) {
    const fileSizeInBytes = model.value.size
    const kilobyte = 1024
    const megabyte = kilobyte * 1024

    if (fileSizeInBytes < kilobyte) {
      return fileSizeInBytes + ' B'
    } else if (fileSizeInBytes < megabyte) {
      return (fileSizeInBytes / kilobyte).toFixed(2) + ' KB'
    } else {
      return (fileSizeInBytes / megabyte).toFixed(2) + ' MB'
    }
  }

  return null
})
const img = computed(() => {
  if (model.value && fileIsImg()) {
    return URL.createObjectURL(model.value)
  } else {
    return null
  }
})
const menuButtonDisabled = computed(() => {
  if (!model.value || props.disabled || props.loading) {
    return true
  }

  return false
})

function onInput(event: Event) {
  const target = event.target as HTMLInputElement
  model.value = target.files?.[0] || null
  if (input.value?.value) {
    input.value.value = ''
  }
}

function fileIsImg() {
  return model.value?.type?.includes('image')
}

function download() {
  if (model.value) {
    downloadFileLink(model.value, model.value?.name)
  }
}

function open() {
  if (model.value) {
    if (img.value) {
      preview.value = true
      nextTick(() => {
        image.value?.onImageClick()
        preview.value = false
      })
    } else {
      console.log('this is PDF')
      window.open(URL.createObjectURL(model.value), '_blank')
    }
  }
}

function onDrop(files: File[] | null) {
  if (files?.length) {
    model.value = files?.[0]
  }
}

const toggle = (event: any) => {
  menu.value.toggle(event)
}

function onMerge(file: File) {
  console.log('onMerge file', file)
  model.value = file
}
</script>
