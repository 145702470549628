import { ComponentPublicInstance, computed, nextTick, ref, toRefs } from 'vue'
import { Nullable, useProvide } from '@/shared'
import { useForm } from '@/shared'
import useMortgageCustomerDeleteModel from './mortgage-customer-delete'
import useMortgageCustomerUpdateModel from './mortgage-customer-update'
import useMortgageCustomerScansModel from './mortgage-customer-scans'
import { useMortgageModel } from '../mortgage-model'
import { CustomerFields } from '@/entities/customer'

type MortgageCustomerFormFields = Partial<Nullable<CustomerFields>>

/**
 ** Фабричная функция, которая создает модель для работы с кастомером
 *   - Имеется useProvide
 */
export const useMortgageCustomerModel = (customer_id?: string) => {
  return useProvide('mortgageCustomer', () => {
    const mortgageModel = useMortgageModel()
    const { mortgage } = toRefs(mortgageModel)

    const customerId = ref<string | null>(customer_id || null)
    const customer = computed(() => {
      if (mortgage.value) {
        if (mortgage.value.customer_applications.main_customer.id === customerId.value) {
          return mortgage.value.customer_applications.main_customer
        } else {
          const customer = mortgage.value?.customer_applications?.customers?.find(
            customer => customer.id === customerId.value
          )
          return customer || null
        }
      }

      return null
    })

    const customerType = computed(() => {
      if (mortgage.value) {
        if (mortgage.value.customer_applications.main_customer.id === customerId.value) {
          return 'main'
        } else {
          return 'customer'
        }
      } else {
        return 'main'
      }
    })

    /** Форма полей анкеты */
    const form = useForm<MortgageCustomerFormFields>()

    const status = computed<{
      completion: number
      message: string | null
    } | null>(() => {
      if (!customer.value) {
        return {
          message: 'Заполните анкету',
          completion: 0
        }
      }

      if (doctypes.value && scans.value) {
        const uploadedScansQuantity = doctypes.value.reduce((acc, doc) => {
          if (scans.value?.find(scan => scan.scan_type === doc.code)) {
            acc += 1
          }

          return acc
        }, 0)
        const completion = (uploadedScansQuantity * 100) / doctypes.value.length
        let message: string | null = null
        if (completion !== 100) {
          const notFilledScans: string[] = []
          doctypes.value.forEach(doc => {
            if (!scans.value?.find(scan => scan.scan_type === doc.code)) {
              notFilledScans.push(doc.title)
            }
          })

          message = 'Не заполнены сканы: ' + notFilledScans.join(', ')
        }

        return {
          message,
          completion: 50 + Math.round(completion / 2)
        }
      }

      return {
        message: null,
        completion: 0
      }
    })

    const container = ref<ComponentPublicInstance>()
    function scrollToFirstError() {
      if (container.value) {
        nextTick(() => {
          const invalidField = container.value?.$el.querySelector('.p-invalid')
          invalidField?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        })
      }
    }

    const panelCollapsed = ref<boolean>(true)
    function togglePanel() {
      panelCollapsed.value = !panelCollapsed.value
    }

    function openPanel() {
      panelCollapsed.value = false
    }

    function closePanel() {
      panelCollapsed.value = true
    }

    function getCustomerFio() {
      const { last_name, first_name, middle_name } = form.values.value
      return `${last_name || ''}  ${first_name || ''} ${middle_name || ''}`.trim()
    }

    const { doctypes, scans, documentTypeDict, getScans, getDoctypes, getDocumentTypeDict } =
      useMortgageCustomerScansModel(customer)

    const {
      update: updateCustomer,
      pending: updateCustomerPending,
      disabled: updateCustomerDisabled,
      disabledReason: updateCustomerDisabledReason
    } = useMortgageCustomerUpdateModel(
      customerId,
      customer,
      customerType,
      form,
      container,
      getCustomerFio,
      scrollToFirstError
    )

    const {
      deleteCustomer,
      disabled: deleteCustomerDisabled,
      disabledReason: deleteCustomerDisabledReason,
      pending: deleteCustomerPending
    } = useMortgageCustomerDeleteModel(customerId, customer, customerType, form, doctypes, scans, getCustomerFio)

    return {
      mortgageModel,

      // customer
      customerId,
      customer,
      customerType,
      getCustomerFio,

      // form
      form,

      // scans
      scans,
      doctypes,
      getScans,
      getDoctypes,
      documentTypeDict,
      getDocumentTypeDict,

      // update
      updateCustomer,
      updateCustomerPending,
      updateCustomerDisabled,
      updateCustomerDisabledReason,

      // delete
      deleteCustomer,
      deleteCustomerDisabled,
      deleteCustomerDisabledReason,
      deleteCustomerPending,

      //other
      status,
      container,
      togglePanel,
      openPanel,
      closePanel,
      panelCollapsed,
      scrollToFirstError
    }
  })()
}
