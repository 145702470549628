import { BankName, DictList, DictValue, TObject, b64File, useApiFetch } from '@/shared'
import {
  MortgageAppFields,
  MortgageBank,
  MortgageCreateApp,
  MortgageDoctype,
  MortgageScan,
  MortgageUpdateApp
} from './api-mortgage.types'
import { useMemoize } from '@vueuse/core'
import { Mortgage, MortgageCalculate, MortgageCalculateProgram, MortgageLimits, MortgagePrintTemplatesNames, MortgageProgram } from '@/entities/mortgage'

export type MortgageDictNames =
  | 'ApartmentType'
  | 'MortgageType'
  | 'PledgeType'
  | 'PledgeReadiness'
  | 'PledgeObject'
  | 'Campaign'
  | 'IncomeConfirmationDocument'
  | 'RealEstateObject'
  | 'TypeOfProperty'

const getDict2 = useMemoize(async (dictName: MortgageDictNames) => {
  const { post } = useApiFetch<DictList>(baseUrl + `dict/${dictName}/get_list`, {}, 'json')
  const res = await post()
  return res?._data
})

const appFields2 = useMemoize(async (bank: BankName) => {
  const { post } = useApiFetch<{ _data: { bank: BankName } }, MortgageAppFields>(
    baseUrl + 'app/fields/required',
    {},
    'json'
  )
  const res = await post({ _data: { bank } })
  return res?._data
})

const baseUrl = 'mortgage/'
export const mortgageApi = {
  /** Получение заявки по id */
  get: () => useApiFetch<{ _data: { id: number } }, Mortgage>(baseUrl + 'get', {}, 'json'),
  /** Получить справочник */
  getDict: (dictName: string) => useApiFetch<DictList>(baseUrl + `dict/${dictName}/get_list/`, {}, 'json'),
  getDict2,
  /** Расчет стоимости ипотеки */
  calculate: () =>
    useApiFetch<{ _data: MortgageCalculate }, MortgageCalculateProgram[]>(baseUrl + 'calculate', {}, 'json'),
  /** Лимиты банковских продуктов */
  limits: () => useApiFetch<{ _data: { salespoint_id: number } }, MortgageLimits>(baseUrl + 'limits', {}, 'json'),
  /** Получить банковский продукт */
  getProgram: () =>
    useApiFetch<{ _data: { id: number } }, MortgageProgram>(baseUrl + 'mortgagedictprogramcode/get', {}, 'json'),
  /** Создать/обновить заявку */
  updateApp: () =>
    useApiFetch<{ _data: MortgageUpdateApp | MortgageCreateApp }, Mortgage>(baseUrl + 'app/update', {}, 'json'),
  /** Обязательные/необходимые поля для анкеты конкретного банка */
  appFields: () =>
    useApiFetch<{ _data: { bank: BankName } }, MortgageAppFields>(baseUrl + 'app/fields/required', {}, 'json'),
  appFields2,
  /** Получить загруженные сканы заявки */
  getScans: () =>
    useApiFetch<{ _data: { mortgageapp_id: number } }, MortgageScan[]>(baseUrl + 'scans/files/get_list', {}, 'json'),
  /** Загрузить скан в заявку */
  uploadScan: () =>
    useApiFetch<
      { _data: { mortgageapp_id: number; customer_id: string; file: b64File; scan_type: DictValue } },
      MortgageScan
    >(baseUrl + 'scans/files/upload', {}, 'json'),
  /** Удалить сканы в заявке */
  deleteScan: () =>
    useApiFetch<{ _data: { mortgageapp_id: number; customer_id: string; item_id: number } }, unknown>(
      baseUrl + 'scans/files/delete',
      {},
      'json'
    ),
  /** Получить список обязательных сканов для заявки */
  getDoctypes: () =>
    useApiFetch<{ _data: { id: number } }, MortgageDoctype[]>(baseUrl + 'scans/doctypes/get_list', {}, 'json'),
  /** Прикрепление/обновление созаёмщика к заявке */
  updateAppCustomer: () =>
    useApiFetch<{ _data: { id: number; customer_id: string } }, Mortgage>(baseUrl + 'app/customer/update', {}, 'json'),
  /** Удаление созаёмщика из заявки */
  deleteAppCustomer: () =>
    useApiFetch<{ _data: { id: number; customer_id: string } }, Mortgage>(baseUrl + 'app/customer/delete', {}, 'json'),
  /** Отправка заявки */
  send: () => useApiFetch<{ _data: { id: number } }, { app: Mortgage }>(baseUrl + 'app/send', {}, 'json'),
  /** Переотправка сканов */
  scansSend: () => useApiFetch<{ _data: { id: number } }, { app: Mortgage }>(baseUrl + 'scans/send', {}, 'json'),
  /** Получить печатную форму */
  print: () =>
    useApiFetch<{ _data: { mortgageapp: number; template: MortgagePrintTemplatesNames } }>(
      baseUrl + 'print_form',
      {},
      'blob'
    ),
  /** Получить печатные формы для анкет */
  getScansApplication: () =>
    useApiFetch<{ _data: { id: number } }, TObject<string, string>>(baseUrl + 'scans/applications/get', {}, 'json'),
  /** Получить банковскую заявку привязанную к ипотеке  */
  getBankApp: () => useApiFetch<{ _data: { id: number } }, { app: MortgageBank }>(baseUrl + 'app/bankapp', {}, 'json')
}
