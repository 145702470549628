import { RouteRecordRaw } from 'vue-router'

export const ReportsRouteNames = {
  base: 'Reports',
  loan: 'Reports Loan',
  stat: 'Reports Stat'
}

export const reportsRoutes: RouteRecordRaw = {
  path: 'reports',
  name: ReportsRouteNames.base,
  redirect: { name: ReportsRouteNames.loan },
  children: [
    {
      path: 'loan',
      component: () => import('@/pages/ReportsMortgageLoanPage'),
      name: ReportsRouteNames.loan
    },
    {
      path: 'stat',
      component: () => import('@/pages/ReportsMortgageStatPage'),
      name: ReportsRouteNames.stat
    }
  ]
}