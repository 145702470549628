import { useApiFetch } from '@/shared/lib'

import { Sex, DictValue, Address, Nullable } from '@/shared'

export type CustomerMeta = {
  id: string
  status: string | 'new'
  user_id: number | null
  mortgage: boolean
}

export type CustomerFields = {
  first_name: string
  last_name: string
  middle_name: string
  changed_name: boolean
  previous_first_name: string | null
  previous_last_name: string | null
  previous_middle_name: string | null
  email: string
  phone: string
  sex: Sex
  citizenship: string | 'RU'
  tax_number: string | null
  insurance_number: string | null
  passport_serial: string
  passport_number: string
  passport_date: string
  passport_division_code: string
  passport_issuing: string
  dateofbirth: string
  birth_place: string | null
  changed_document: boolean
  old_passport_serial: string | null
  old_passport_number: string | null
  old_passport_date: string | null
  old_passport_division_code: string | null
  marital_status: DictValue | null
  dependents: number | null
  dependents_birth_dates: string | null
  education: DictValue | null
  employment_type: DictValue | null
  employer_name: string | null
  employer_phone: string | null
  employment_position: string | null
  employment_position_type: DictValue | null
  total_experience: number | null
  work_tax_number: string | null
  work_address: Address | null
  employment_experience: number | null
  employment_income: number | null
  proof_employment_income: number | null
  work_employees_count: number | null
  work_business_share: number | null
  employer_foundation_date: string | null
  income_confirmation_document: DictValue | null
  employer_okved: string | null
  employer_activity: DictValue | null
  add_employment_type: DictValue | null
  add_employer_okved: string | null
  add_employer_name: string | null
  add_employer_phone: string | null
  add_employment_position: string | null
  add_employment_position_type: string | null
  add_work_tax_number: string | null
  add_work_address: Address | null
  add_employment_experience: number | null
  add_employment_income: number | null
  add_proof_employment_income: number | null
  add_work_employees_count: number | null
  add_work_business_share: number | null
  add_employer_foundation_date: string | null
  add_income_confirmation_document: string | null
  add_income_type: DictValue | null
  add_income: number | null
  add_employer_activity: DictValue | null
  loan_payments: number | null
  alimony: number | null
  loan_for_fee: boolean
  change_job: boolean
  maternity_leave: boolean
  public_official_person: boolean
  address_country: string | null
  address_postcode: string | null
  address_region: string | null
  address_city: string | null
  address_street: string | null
  address_house: string | null
  address_flat: string | null
  address: Address | null
  address_status: DictValue | null
  actual_address: Address | null
  is_lawyer: boolean
  add_is_lawyer: boolean
  is_notary: boolean
  add_is_notary: boolean
  nature_of_work: DictValue | null
  add_nature_of_work: DictValue | null
  is_guarantor: boolean
  coborrower_status: DictValue | null
  employer_activity_in_company: DictValue | null
  employer_legal_form: DictValue | null
  employer_other_legal_form: string | null
  relative_person: boolean | null
  add_employer_legal_form: DictValue | null
  add_employer_activity_in_company: DictValue | null
  add_employer_other_legal_form: string | null
  is_it_mortgage_participant: boolean | null
  is_subsidy_kids_participant: boolean | null
  disabled_dependent_birth_date: string | null
  usa_tax_consent: boolean | null
  usa_tax_payer: boolean | null
  foreign_tax_resident: boolean | null
  own_funds_amount: number | null
  address_type: boolean | null
  address_phone: string | null
  actual_address_phone: string | null
  marriage_contract: boolean | null
  notarial_consent: boolean | null

  dependents_citizenship: string | null

  // 24.06.24
  is_spouse_coborrower: boolean
  agreement_to_bki_request: boolean
  bki_agreement_date: string
  owns_international_passport: boolean
  tax_resident_of_russia: boolean
  tax_resident_of_another_country: boolean
  akbars_is_bank_employee: boolean
  akbars_is_card_holder: boolean
  akbars_digital_consent: boolean
  akbars_is_certificate_of_repayment: boolean
  akbars_property_insurance: boolean
  akbars_collective_insurance_contract: boolean
}

export type Customer = CustomerFields & CustomerMeta

export type CustomerUpdate = Partial<Nullable<CustomerFields> & { id: string }>

export type CustomerFindData = {
  last_name: string
  passport_number: string
  passport_serial?: string
}

const baseUrl = 'customer/'

/** Обновление кастомера */
export const useCustomerUpdate = () =>
  useApiFetch<{ _data: CustomerUpdate }, Customer>(baseUrl + 'update', {}, 'json')

/** Поиск кастомера */
export const useCustomerSearch = () =>
  useApiFetch<{ _filter: CustomerFindData; _pageSize: 1 }, Customer[]>(baseUrl + 'get_list', {}, 'json')
