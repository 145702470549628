import { RouteRecordRaw } from 'vue-router'

export const accountRouteNames = {
  login: 'AuthLogin'
}

export const authRoutes: RouteRecordRaw = {
  path: '/auth',
  redirect(to) {
    return { path: accountRouteNames.login }
  },
  children: [
    {
      path: 'login',
      name: accountRouteNames.login,
      component: () => import('@/pages/AuthLoginPage')
    }
  ]
}
