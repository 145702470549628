<template>
  <BaseContent>
    <div class="text-xl text-gray-900 font-medium mb-4">Банковские продукты</div>

    <DataTable
      scrollable
      size="small"
      class="mortgage-table"
      selectionMode="single"
      :pt="{
        columnheader: 'bg-transparent'
      }"
      :value="programs"
      :selection="program"
      :metaKeySelection="false"
      :loading="loading"
      @row-select="onRowSelect"
      @row-click="onRowClick"
    >
      <Column
        field="title"
        header="Название"
      >
        <template #body="{ data }">
          <div>{{ data.product.title }}</div>
        </template>
      </Column>

      <Column
        field="bank"
        header="Банк"
      >
        <template #body="{ data }">
          <BaseBankIcon :bankName="data.product.bank" />
        </template>
      </Column>

      <Column
        field="rate"
        header="Ставка"
      >
        <template #body="{ data }"> от {{ data.loan_rate }}% </template>
      </Column>

      <Column
        field="payment"
        header="Ежемес. платеж"
      >
        <template #body="{ data }"> {{ data.monthly_payment.toLocaleString() }}₽ </template>
      </Column>

      <Column
        field="sum"
        header="Сумма кредита"
      >
        <template #body="{ data }"> {{ data.credit_amount.toLocaleString() }}₽ </template>
      </Column>

      <Column
        field="overpayment"
        header="Переплата"
      >
        <template #body="{ data }"> {{ data.overpayment.toLocaleString() }}₽ </template>
      </Column>

      <Column
        field="down_payment"
        header="ПВ"
      >
        <template #body="{ data }"> {{ data.down_payment.toLocaleString() }}₽ </template>
      </Column>

      <template #empty>
        <div class="text-center">
          <span class="text-gray-900 text-base font-medium">Нет подходящих продуктов</span>
        </div>
      </template>
    </DataTable>
  </BaseContent>
</template>

<script setup lang="ts">
import { computed, defineModel } from 'vue'
import { BaseBankIcon, BaseContent } from '@/shared'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import { MortgageCalculateProgram } from '../../types';

const props = defineProps<{
  programs: MortgageCalculateProgram[] | null
  loading?: boolean
}>()

const programId = defineModel<number | null>('programId')
const program = computed(() => {
  return props.programs?.find(program => program.product.id === programId.value) || null
})

function onRowSelect(event: any) {
  const data = event.data as MortgageCalculateProgram
  programId.value = data.product.id
}

function onRowClick(event: any) {
  const data = event.data as MortgageCalculateProgram
  if (data.product.id === programId.value) {
    programId.value = null
  }
}
</script>

<style lang="scss">
.mortgage-table {
  th {
    background: white;
  }

  td {
    border: none;
  }
}
</style>
