<template>
  <BaseContent>
    <BaseTitleModal
      label="Выбор торговой точки"
      :close="closeButtonVisible"
      @close="close"
    />

    <BaseDiver />

    <div class="flex flex-col gap-5">
      <BaseInputForm
        id="salespoint_id"
        required
        class="col-span-full"
        label="Торговая точка"
        :error="meta.error"
      >
        <InputSalespoint
          id="salespoint_id"
          v-model="model"
          :invalid="meta.invalid"
          :disabled="meta.disabled"
          @blur="onBlur"
        />
      </BaseInputForm>
    </div>

    <BaseDiver />

    <div
      v-tooltip.bottom="disabledReason"
      class="w-fit mt-2"
    >
      <Button
        label="Выбрать"
        :disabled="disabled"
        :pending="pending"
        @click="onSubmit"
      />
    </div>
  </BaseContent>
</template>

<script setup lang="ts">
import { useToast } from 'primevue/usetoast'
import { useSalespointStore } from '@/store'
import { Ref, computed, inject } from 'vue'
import { DynamicDialogInstance } from 'primevue/dynamicdialogoptions'
import {
  BaseInputForm,
  BaseContent,
  BaseDiver,
  BaseTitleModal,
  requiredValidator,
  useControl,
  InputSalespoint
} from '@/shared'
import Button from 'primevue/button'
import { useGetSalespoint } from 'simpleloan_api'
import { useFeatureSelectSalespointModel } from '../../model'

const dialogRef = inject<Ref<DynamicDialogInstance>>('dialogRef')

const toast = useToast()

const { select } = useFeatureSelectSalespointModel()

const salespointStore = useSalespointStore()

const salespointIdControl = useControl<number>('salespoint', { validators: [requiredValidator] })

const { model, meta, onBlur } = salespointIdControl

const { post, pending } = useGetSalespoint()

const closeButtonVisible = computed(() => Boolean(salespointStore.salespoint))

const disabledReason = computed(() => {
  if (!model.value) {
    return 'Выберите торговую точку'
  }

  return null
})

const disabled = computed(() => Boolean(disabledReason.value))

function onSubmit() {
  if (model.value) {
    post({ body: { id: model.value } }).then(res => {
      if (res?._code === 0 && res._data) {
        select(res._data)
        close()
      } else {
        toast.add({ severity: 'error', summary: 'Получение торговой точки', detail: res?._message })
      }
    })
  }
}

function close() {
  dialogRef?.value.close()
}
</script>
