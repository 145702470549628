import { computed, reactive, toRefs } from 'vue'
import { getUserFio } from '@/entities/user'
import { Rosbankdom } from '@/entities/rosbankdom'
import { MortgageModel, useMortgageModel } from '../mortgage-model'
import { getMainCustomerFio } from '../../lib'
import { TMortgagesCalculatorForm } from '../mortgage-store'
import { ModelType, useModel, BankName, Salespoint } from '@/shared'
import { computedAsync } from '@vueuse/core'
import { mortgageApi } from '../../api'
import { Mortgage, MortgageProgram } from '../../types'
import { AlfabankBalance, MortgageBankNames } from 'simpleloan_api'

type MortgageInfoModel = {
  mortgage: Mortgage | null
  bankNameTranslate: string | null
  conditions: TMortgagesCalculatorForm | undefined
  apartmentCity: string | null | undefined
  creditTerm: number
  userFio: string | null
  downPayment: string
  creditAmount: string
  mainCustomerFio: string | null
  maternal_capital_sum: string
  maternal_capital_sum_in: string | null
  pledge_type: string | null
  pledge_readiness: string | null
  pledge_object: string | null
  bankName: BankName | null
  bankId: string | null
  salespoint: Salespoint | null
  program: MortgageProgram | null
  // campaign: string | null
}

const _bankNameTranslate: Record<MortgageBankNames, string> = {
  absolutbank: 'Абсолют Банк',
  akbars: 'Ак Барс Банк',
  alfabankbalance: 'Альфа-Банк',
  gazprombankm: 'Газпромбанк',
  rosbankdom: 'Росбанк',
  vtbbankm: 'Банк ВТБ'
}

const createMortgageInfoModel = (propMortgageModel?: MortgageModel): MortgageInfoModel => {
  const mortgageModel = useMortgageModel({ model: propMortgageModel })
  const { mortgage, bankName, user, salespoint, program, preMortgageData } = toRefs(mortgageModel)
  const bankNameTranslate = computed(() => (bankName.value ? _bankNameTranslate[bankName.value] : null))
  const conditions = computed(() => preMortgageData.value?.conditions)
  const apartmentCity = computed(() => mortgage.value?.apartment_city || conditions.value?.apartment_city)
  const creditTerm = computed(() => (mortgage.value?.credit_term || conditions.value?.credit_term || 0) / 12)
  const userFio = computed(() => (user.value ? getUserFio(user.value) : null))
  const downPayment = computed(() =>
    Number(mortgage.value?.down_payment || conditions.value?.down_payment).toLocaleString()
  )
  const creditAmount = computed(() =>
    Number(mortgage.value?.credit_amount || conditions.value?.credit_amount).toLocaleString()
  )
  const mainCustomerFio = computed(() => {
    if (mortgage.value) {
      return getMainCustomerFio(mortgage.value)
    }

    return null
  })

  const maternal_capital_sum = computed(() =>
    Number(mortgage.value?.maternal_capital_sum || conditions.value?.maternal_capital_sum).toLocaleString()
  )
  const maternal_capital_sum_in = computed(() => {
    if (mortgage.value?.maternal_capital_in_down_payment || conditions.value?.maternal_capital_in_down_payment) {
      return 'В первоначальном взносе'
    }

    if (mortgage.value?.maternal_capital_in_loan_sum || conditions.value?.maternal_capital_in_loan_sum) {
      return 'В сумме кредита'
    }

    return null
  })

  const pledge_type = computedAsync(async () => {
    const value = mortgage.value?.pledge_type || conditions.value?.pledge_type
    if (value) {
      const dict = await mortgageApi.getDict('PledgeType')
      return dict?.find(dict => dict.code === value)?.title || null
    }

    return null
  })
  const pledge_readiness = computedAsync(async () => {
    const value = mortgage.value?.pledge_readiness || conditions.value?.pledge_readiness
    if (value) {
      const dict = await mortgageApi.getDict('PledgeReadiness')
      return dict?.find(dict => dict.code === value)?.title || null
    }

    return null
  })
  const pledge_object = computedAsync(async () => {
    const value = mortgage.value?.pledge_object || conditions.value?.pledge_object
    if (value) {
      const dict = await mortgageApi.getDict('PledgeObject')
      return dict?.find(dict => dict.code === value)?.title || null
    }

    return null
  })

  const campaign = computed(() => mortgage.value?.campaign || conditions.value?.combo_flag)

  const bankId = computed(() => {
    if (mortgageModel.bankApp) {
      if (mortgageModel.bankName === 'alfabankbalance') {
        return (mortgageModel.bankApp as AlfabankBalance)?.short_id
      }

      if (mortgageModel.bankName === 'rosbankdom') {
        return (mortgageModel.bankApp as Rosbankdom).bank_id
      }
    }

    return null
  })

  return reactive({
    mortgage,
    bankNameTranslate,
    conditions,
    apartmentCity,
    creditTerm,
    userFio,
    downPayment,
    creditAmount,
    mainCustomerFio,
    maternal_capital_sum,
    maternal_capital_sum_in,
    pledge_type,
    pledge_readiness,
    pledge_object,
    bankName,
    bankId,
    salespoint,
    program
    // campaign,
  })
}

/**
 ** Фабричная функция, которая создает модель для работы с информацией по заявке
 */
export const useMortgageInfoModel = (
  type: ModelType = 'local',
  mortgageModel?: MortgageModel,
  model?: MortgageInfoModel
) => useModel('mortgage-info', () => createMortgageInfoModel(mortgageModel), type, model)
