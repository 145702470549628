import { ref } from 'vue'
import { defineStore } from 'pinia'
import { Salespoint, useGetSalespoint } from '@/shared'
import { useSalespointModel } from '@/entities/salespoint'


const localStorageItemName = 'salespoint_id_mortgage'

export const useSalespointStore = defineStore('salespoint', () => {
  const salespointModel = useSalespointModel()
  const salespoint = ref<Salespoint | null>(null)

  const { post } = useGetSalespoint()

  function set(sp: Salespoint) {
    salespoint.value = sp
    salespointModel.salespoint = sp
    if (salespoint.value.id) {
      localStorage.setItem(localStorageItemName, salespoint.value.id?.toString())
    }
  }

  async function setLocalStorageSalespoint() {
    const id = Number(localStorage.getItem(localStorageItemName))
    if (id) {
      return post({ _data: { id } }).then(res => {
        if (res?._code === 0 && res._data) {
          salespointModel.salespoint = res._data
          salespoint.value = res._data
        }
        
        return res
      })
    }

    return null
  }

  function drop() {
    salespoint.value = null
    salespointModel.salespoint = null
    localStorage.removeItem(localStorageItemName)
  }

  return {
    salespoint,
    set,
    drop,
    setLocalStorageSalespoint
  }
})
