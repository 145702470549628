import { RouteRecordRaw } from 'vue-router'

export const UserRouteNames = {
  base: 'User',
}

export const userRoutes: RouteRecordRaw = {
  path: 'user/:id',
  component: () => import('@/pages/UserPage'),
  name: UserRouteNames.base,
}