<template>
  <BaseContent class="rounded-0">
    <div class="flex flex-col justify-between h-full">
      <div>
        <div class="flex justify-between">
          <div class="text-2xl font-medium mb-2">{{ mortgage?.id || '' }}</div>
          <slot name="header"></slot>
        </div>

        <BaseDiver />
        <MortgageStatus
          v-if="mortgage"
          v-tooltip.bottom="'Статус'"
          class="text-sm"
          :mortgage="mortgage"
        />

        <template v-if="mainCustomerFio">
          <BaseDiver />

          <span
            v-tooltip.bottom="'Клиент/Заёмщик'"
            class="text-gray-900 font-medium"
          >
            {{ mainCustomerFio }}
          </span>
        </template>

        <template v-if="mortgage?.bank_app_status?.comment">
          <BaseDiver />

          <div class="flex flex-wrap">
            <dt class="text-gray-500 font-medium min-w-[120px]">Комментарий:</dt>
            <dd class="text-gray-700 font-medium m-0">{{ mortgage?.bank_app_status.comment }}</dd>
          </div>
        </template>

        <BaseDiver />

        <div class="flex flex-col gap-1 mb-1">
          <!-- <div class="flex gap-1">
            <dt class="text-gray-500 font-medium min-w-[120px]">Номер</dt>
            <dd class="text-gray-700 font-medium m-0">{{ mortgage?.id || '-' }}</dd>
          </div> -->

          <div class="flex gap-1">
            <dt class="text-gray-500 font-medium min-w-[120px]">Банк</dt>
            <dd class="text-gray-700 font-medium m-0">{{ bankNameTranslate }}</dd>
          </div>

          <div class="flex gap-1">
            <dt class="text-gray-500 font-medium min-w-[120px]">Ставка</dt>
            <dd class="text-gray-700 font-medium m-0">от {{ program?.loan_rate }} %</dd>
          </div>

          <div class="flex gap-1">
            <dt class="text-gray-500 font-medium min-w-[120px]">Сумма</dt>
            <dd class="text-gray-700 font-medium m-0">{{ creditAmount }} ₽</dd>
          </div>

          <div class="flex gap-1">
            <dt class="text-gray-500 font-medium min-w-[120px]">ПВ</dt>
            <dd class="text-gray-700 font-medium m-0">{{ downPayment }} ₽</dd>
          </div>

          <div class="flex gap-1">
            <dt class="text-gray-500 font-medium min-w-[120px]">Срок</dt>
            <dd class="text-gray-700 font-medium m-0">{{ creditTerm }} лет</dd>
          </div>

          <div class="flex gap-1">
            <dt class="text-gray-500 font-medium min-w-[120px]">Город</dt>
            <dd class="text-gray-700 font-medium m-0">{{ apartmentCity }}</dd>
          </div>

          <div class="flex gap-1">
            <dt class="text-gray-500 font-medium min-w-[120px]">Программа</dt>
            <dd
              v-tooltip.bottom="program?.title"
              class="text-gray-700 font-medium m-0 truncate"
            >
              {{ program?.title }}
            </dd>
          </div>

          <div class="flex gap-1">
            <dt class="text-gray-500 font-medium min-w-[120px]">Торговая т.</dt>
            <dd
              v-tooltip.bottom="salespoint?.title"
              class="text-gray-700 font-medium m-0 truncate"
            >
              {{ salespoint?.title }}
            </dd>
          </div>

          <div
            v-tooltip="maternal_capital"
            class="flex gap-1"
          >
            <dt class="text-gray-500 font-medium min-w-[120px]">Мат. капитал</dt>
            <dd class="text-gray-700 font-medium m-0 truncate">
              {{maternal_capital }}
            </dd>
          </div>

          <div
            v-tooltip="pledge"
            class="flex gap-1"
          >
            <dt class="text-gray-500 font-medium min-w-[120px]">Залог</dt>
            <dd class="text-gray-700 font-medium m-0 truncate">
              {{ pledge }}
            </dd>
          </div>

          <div class="flex gap-1">
            <dt class="text-gray-500 font-medium min-w-[120px]">Банк. номер</dt>
            <dd class="text-gray-700 font-medium m-0">{{ bankId }}</dd>
          </div>
        </div>
      </div>

      <div>
        <BaseDiver />
        <span
          v-tooltip.bottom="'Автор заявки'"
          class="text-gray-500 font-medium"
        >
          {{ userFio }}
        </span>
        <BaseDiver />

        <slot name="actions"></slot>
      </div>
    </div>
  </BaseContent>
</template>

<script setup lang="ts">
import { MortgageModel, useMortgageInfoModel } from '../../model'
import { BaseContent, BaseDiver } from '@/shared'
import { MortgageStatus } from '../MortgageStatus'
import { computed, toRefs } from 'vue'

const props = defineProps<{
  mortgageModel?: MortgageModel
}>()

const {
  mortgage,
  bankNameTranslate,
  conditions,
  apartmentCity,
  creditTerm,
  userFio,
  downPayment,
  creditAmount,
  mainCustomerFio,
  maternal_capital_sum,
  maternal_capital_sum_in,
  pledge_type,
  pledge_readiness,
  pledge_object,
  bankName,
  bankId,
  salespoint,
  program
} = toRefs(useMortgageInfoModel('local', props.mortgageModel))

const maternal_capital = computed(() => {
  if (maternal_capital_sum.value && maternal_capital_sum_in.value) {
    return `${maternal_capital_sum.value} ₽, ${maternal_capital_sum_in.value}`
  }

  return null
})

const pledge = computed(() => {
  if (pledge_type.value && pledge_readiness.value && pledge_object.value) {
    return `${pledge_type.value}, ${pledge_readiness.value}, ${pledge_object.value}`
  }

  return null
})
</script>
