import { useApiFetch } from '@/shared'
import { FileInBase64 } from '@/shared'

export type UtilsPdfMergeRequest = {
  _data: {
    files: FileInBase64[]
  }
}

const baseUrl = 'utils/'

/** Объединить несколько PDF-файлов в один */
export const useUtilsPdfMerge = () => useApiFetch<UtilsPdfMergeRequest, string>(baseUrl + 'pdf/merge', {}, 'json')
